<template>
  <v-card class="card-shadow mt-6">
    <div class="card-header-padding card-border-bottom">
      <v-row class="mt-2">
        <v-col cols=5>
          <p class="font-weight-600 text-h3 mb-0">Proxy Users List</p>
        </v-col>

        <v-tooltip color="#312529" top>
          <template v-slot:activator="{ on, attrs }">
            <v-col
              v-bind="attrs"
              v-on="on"
              cols=4 class="text-right pt-0"
            >
              <v-checkbox
                class="font-size-input text-light-input mt-2"
                v-model="tableFilters.includeTracking"
                v-on:change="getFullInfo2"

                label="Include 'Tracking Only'"
                hide-details
                light
              ></v-checkbox>
            </v-col>
          </template>
          <span>Applies only if no status-filter is selected</span>
        </v-tooltip>
        <v-col cols=3 class="text-right pt-0">
          <v-select
            small
            placeholder="All Users"
            hide-details
            item-text="name"
            item-value="value"
            v-model="tableFilters.selectedStatus"
            v-on:change="getFullInfo2"
            :items="tableFilters.statuses"
            class="input-style font-size-input text-light-input placeholder-light select-style pr-3"
            outlined
            single-line
          >
          </v-select>
        </v-col>
      </v-row>
    </div>

<!-- ------------------------------------------------------------------------- -->
<!-- LOADING DIALOG -->

    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      persistent
      width="150"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="py-5">
          Loading...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


<!-- ------------------------------------------------------------------------- -->
<!-- MIGRATE USER CONFIRM DIALOG -->

    <v-dialog
      v-model="dialogConfirmMigrate"
      persistent
      max-width="800"
    >

      <v-card>
        <v-card-title class="text-h5">
          <h2>Migrate the user {{ migrateLogin }} to the system?</h2>
          </v-card-title>
          <v-card-text>
            This client is currently in a tracking mode. You can only view traffic usage statistics for this client. With this action, you migrate the client to the system completely: the system will manage the client's access to proxy services, including blocking when a negative balance is reached. You will also be able to edit the client (including available balance, passwords etc.) using the current panel.</br></br>

            The client will be assigned the specified email and password <u>12345</u> to access the user dashboard. The proxy access credentials remains unchanged for the client; proxy-password won't show up in the panel until it's changed at least once.</br></br>

            <v-divider class="mb-6"></v-divider>

            <span class="font-weight-600">Please enter the email that will be assigned to the account, as well as the current traffic balance in the previous system. Please note that if Rebilly billing account is already linked to the email, it will be synchronized automatically.</span></br></br>

              <div class="mb-6">
                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Email address:</label
                    >
                    <v-text-field
                      type="email"
                      hide-details
                      outlined
                      v-model="migrateEmail"
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="email@example.com"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Bandwidth Balance [Mb]:</label
                    >
                    <v-text-field
                      v-model="migrateBalance" :min="0"
                      type="number"
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="0"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Proxy Host (optional):</label
                    >
                    <v-text-field
                      type="text"
                      hide-details
                      outlined
                      v-model="migrateHost"
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="la.residential.rayobyte.com"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Proxy Port (optional):</label
                    >
                    <v-text-field
                      v-model="migratePort" :min="80" :max="65536"
                      type="number"
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="8000"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>


<b>Are you sure you want to migrate the client with login <u>{{ migrateLogin }}</u></b> (user ID {{ migrateId }}) <b>to the system and give the system the ability to manage its access to proxy services? The action can't be undone.</b>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="cancelMigration"
          >
            Cancel
          </v-btn>
          <v-btn
            color="darken-1"
            text
            @click="migrateIntoSystem"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-card-text class="px-0 py-0">
      <v-simple-table class="table">
        <template v-slot:default>
          <thead class="thead-light">
            <tr>
              <th class="text-left ps-6">User</th>
              <th class="text-left ps-6">Created At</th>
              <th class="text-left">Available bandwidth</th>
              <th class="text-left">Used total</th>
              <th class="text-left">Used today</th>
              <th class="text-left">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in projectsFilteredCurrentPage" :key="item.id" v-on:click="changeMain">
              <td class="border-bottom">
                <v-list class="py-0">
                  <v-list-item class="px-0 py-4">
                    <v-list-item-avatar :size="48" class="my-0 me-5">
                      <v-img :alt="item.imgSrc" :src="item.imgSrc"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="text-body text-h5 ls-0 font-weight-600"
                        ><b>{{ item.login }}</b></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </td>
              <td class="text-body border-bottom text-h5">
                {{ item.date }}
              </td>
              <td class="text-body border-bottom text-h5">
                <a href="javascript:;" class="text-decoration-none">{{
                  item.balance
                }}</a>
              </td>
              <td class="text-body border-bottom text-h5">
                <a href="javascript:;" class="text-decoration-none">{{
                  item.usedTotal
                }}</a>
              </td>
              <td class="text-body border-bottom text-h5">
                <a href="javascript:;" class="text-decoration-none">{{
                  item.usedToday
                }}</a>
              </td>
              <td class="text-body border-bottom text-h5">
                {{
                  item.status
                }}
              </td>

              <td v-if="item.status == 'TRACKING_ONLY'" class="border-bottom">
                <v-tooltip color="#312529" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-on:click="openMigrateConfirmDialog"
                      size="14"
                      class="text-muted me-2 cursor-pointer"
                      >ni ni-curved-next</v-icon
                    >
                  </template>
                  <span>Migrate into the system</span>
                </v-tooltip>
              </td>

              <td v-else class="border-bottom">
                <v-tooltip color="#312529" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-on:click.stop="editPrices(item.id)"
                      size="14"
                      class="text-muted me-2 cursor-pointer"
                      >fa fa-bookmark</v-icon
                    >
                  </template>
                  <span>Custom Prices</span>
                </v-tooltip>
                  <v-tooltip color="#312529" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-on:click="editPage"
                      size="14"
                      class="text-muted me-2 cursor-pointer"
                      >fas fa-user-edit</v-icon
                    >
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip color="#212529" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="14"
                      v-on:click="suspendUser"
                      class="text-muted me-2 cursor-pointer"
                      >ni ni-button-pause</v-icon
                    >
                  </template>
                  <span>Suspend/Unsuspend</span>
                </v-tooltip>
                <v-tooltip color="#212529" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="14"
                      v-on:click="deleteUser"
                      class="text-muted me-2 cursor-pointer"
                      >fas fa-trash</v-icon
                    >
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>

        </template>
      </v-simple-table>
    </v-card-text>

      <v-dialog
          v-model="priceDialog"
          persistent
          max-width="600px"
      >

          <v-card>
              <v-card-title>
                  <span class="text-h5">Custom Prices For User</span>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col
                              v-for="(item, index) in prices" :key="index"
                              cols="12"
                              sm="6"
                              md="4"
                          >
                              <v-text-field
                                  :label="prices[index].title"
                                  v-model="prices[index].price"
                                  required
                              ></v-text-field>
                          </v-col>

                      </v-row>
                      <v-checkbox
                          v-model="enabled"
                          label="Custom Prices Enabled"
                      ></v-checkbox>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="priceDialog = false"
                  >
                      Close
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="updatePrices"
                  >
                      Save
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </v-card>

</template>
<script>

export default {
  name: "light-table",
  props: {
    searchValue: String,
    page: Number,
  },
  watch: {
    searchValue: function (val, oldVal) {
      if (val && val != '') {
        // Get data with search
        this.getFullInfoWithSearch(val.toLowerCase());
      } else {
        // Get data // page 1, then reset page
        this.getFullInfo2();
      }

      // this.$data.projectsFiltered = this.$data.projects.filter(item => item.login.toLowerCase().includes(val.toLowerCase()));
      // this.resetPage();
    },
    page: function(newVal, oldVal) {
      this.$data.pagination.page = newVal;
      this.$data.pagination.startIndex = (newVal - 1) * this.$data.pagination.pageSize;
      // this.$data.projectsFilteredCurrentPage = [];
      this.getFullInfo2(this.$data.pagination.page);
      // for (let i = 0; i < this.$data.pagination.pageSize; i++) {
      //   if (this.$data.pagination.startIndex + i < this.$data.projectsFiltered.length) {
      //     this.$data.projectsFilteredCurrentPage.push(this.$data.projectsFiltered[this.$data.pagination.startIndex + i]);
      //   }
      // }
    },
  },
  data() {
    return {
      token: localStorage.getItem('jwt'),
      host:  this.$siteConfig.resellerApiHost,
      paths: {
        resellerInfo: `${this.$siteConfig.resellerApiBasePath}/reseller/info`,
        users: `${this.$siteConfig.resellerApiBasePath}/reseller/users`,
        usersFlexPlans: `${this.$siteConfig.resellerApiBasePath}/reseller/flex-plans`,
      },
      pagination: {
        page: 1,
        pageSize: 100,
        startIndex: 0,
      },
      dialogConfirmMigrate: false,
      dialogLoading: false,
      migrateId: '',
      migrateLogin: '',
      migrateEmail: '',
      migrateBalance: 0,
      migrateHost: '',
      migratePort: 0,

      pics: [
        require("@/assets/img/team-1.jpg"),
        require("@/assets/img/team-2.jpg"),
        require("@/assets/img/team-3.jpg")
      ],
      clickedUserId: '',
      priceDialog: false,
      enabled: false,
      defaultPrices: [
          {id: 'residential-traffic1',title: 'Starter Plan', price: 15, min: 1, max:15},
          {id: 'residential-traffic2',title: 'Personal Plan', price: 12.5, min: 16, max:49},
          {id: 'residential-traffic3',title: 'Consumer Plan', price: 7, min: 50, max:99},
          {id: 'residential-traffic4',title: 'Professional Plan', price: 6, min: 100, max:249},
          {id: 'residential-traffic5',title: 'Corporate Plan', price: 4, min: 500, max:999},
          {id: 'residential-traffic6',title: 'Enterprise Plan', price: 3, min: 1000, max:4999},
          {id: 'residential-traffic7',title: 'Business Plan', price: 5, min: 250, max:499},
          {id: 'residential-traffic8',title: 'Custom Plan', price: 1, min: 5000, max:999999},
      ],
      prices:[],
      tableFilters: {
        includeTracking: false,
        selectedStatus: 'ALL',
        statuses: [
          {name: "All", value: "ALL"},
          {name: "Active", value: "ACTIVE"},
          {name: "Suspended", value: "SUSPENDED"},
          {name: "Blocked", value: "BLOCKED"},
          {name: "Not in the system", value: "TRACKING_ONLY"},
        ]
      },
      projectsFiltered: [],
      projectsFilteredCurrentPage: [],
      projects: [
        // {
        //   id: 0,
        //   login: "John Michael",
        //   imgSrc: '',
        //   date: "01/01/2022",
        //   balance: "6.1 GB",
        //   usedTotal: "0 KB",
        //   usedToday: "0 KB",
        //   status: "Active",
        // },
        // {
        //   id: 1,
        //   login: "Alexandra Smith",
        //   imgSrc: '',
        //   date: "23/12/2021",
        //   balance: "0 MB",
        //   usedTotal: "0 KB",
        //   usedToday: "0 KB",
        //   status: "Suspended",
        // },
        // {
        //   id: 2,
        //   login: "Samantha Ivy",
        //   imgSrc: '',
        //   date: "22/12/2021",
        //   balance: "930 MB",
        //   usedTotal: "0 KB",
        //   usedToday: "0 KB",
        //   status: "Active",
        // },
        // {
        //   id: 3,
        //   login: "John Michael",
        //   imgSrc: '',
        //   date: "10/12/2021",
        //   balance: "2.4 GB",
        //   usedTotal: "0 KB",
        //   usedToday: "0 KB",
        //   status: "Active",
        // },
        // {
        //   id: 4,
        //   login: "Alexandra Smith",
        //   imgSrc: '',
        //   date: "15/11/2021",
        //   balance: "1.3 GB",
        //   usedTotal: "0 KB",
        //   usedToday: "0 KB",
        //   status: "Active",
        // },
        // {
        //   id: 4,
        //   login: "Masha Ololoeva",
        //   imgSrc: '',
        //   date: "30/09/2020",
        //   balance: "21 GB",
        //   usedTotal: "0 KB",
        //   usedToday: "0 KB",
        //   status: "Active",
        // },
      ],
    };
  },
  methods: {
    getFullInfo2: function(page) {
        let getPage = page || 1;
        if(page == true || page == false) {
          getPage = 1;
        }
        if (isNaN(page)) { // on status change its value is passed to the callback
          getPage = 1;
        }

        this.$data.dialogLoading = true;
        let fullInfoPath = `${this.$data.host}${this.$data.paths.resellerInfo}?page=${getPage}&pageSize=${this.$data.pagination.pageSize}`;

        if (this.$data.tableFilters.selectedStatus == 'ACTIVE') {
          fullInfoPath += '&statusOnly=ACTIVE';
          this.$data.tableFilters.includeTracking = false;
        } else if (this.$data.tableFilters.selectedStatus == 'SUSPENDED') {
          fullInfoPath += '&statusOnly=SUSPENDED';
          this.$data.tableFilters.includeTracking = false;
        } else if (this.$data.tableFilters.selectedStatus == 'BLOCKED') {
          fullInfoPath += '&statusOnly=BLOCKED';
          this.$data.tableFilters.includeTracking = false;
        } else if (this.$data.tableFilters.selectedStatus == 'TRACKING_ONLY') {
          fullInfoPath += '&statusOnly=TRACKING_ONLY';
        } else if (this.$data.tableFilters.includeTracking) {
          fullInfoPath += '&includeTracking=true';
        }

        fetch(fullInfoPath, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.projects = [];
                this.$data.projectsFiltered = [];
                for (const user of json.data.proxyUsers.all) {
                  const pic = this.$data.pics[Math.floor(Math.random() * this.$data.pics.length)];
                  const buf = {
                    id: user.id,
                    login: user.login,
                    imgSrc: pic,
                    date: new Date(user.created).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric' }),
                    balance: user.trafficBalanceString,
                    usedTotal: user.trafficConsumedString.replace('undefined', '0'),
                    usedToday: user.trafficConsumedTodayString.replace('undefined', '0'),
                    status: user.status,
                  }
                  this.$data.projects.push(buf);
                }
                // this.$data.projectsFiltered = this.$data.projects.filter(item => item.login.toLowerCase().includes(this.searchValue.toLowerCase()));
                this.$data.projectsFiltered = this.$data.projects;
                this.$data.projectsFilteredCurrentPage = this.$data.projectsFiltered;

                if (!page || page == true || isNaN(page)) {
                  this.resetPage(json.data.proxyUsers.count); // recalculate pagination and go to the first page
                }
                this.$data.dialogLoading = false;
              } catch(e) {
                this.$data.dialogLoading = false;
                console.log('Parsing error!!!!!!!!!!!', e);
              }
            } else if (json && json.error) {
              this.$data.dialogLoading = false;
              alert(json.error);
            } else {
              this.$data.dialogLoading = false;
              throw new Error('Authentication error');
            }
          })
          .catch((e) => {
            this.$data.dialogLoading = false;
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },

    getFullInfoWithSearch: function(search) {
        this.$data.dialogLoading = true;
        let fullInfoPath = `${this.$data.host}${this.$data.paths.resellerInfo}?search=${search}`;

        if (this.$data.tableFilters.selectedStatus == 'ACTIVE') {
          fullInfoPath += '&statusOnly=ACTIVE';
          this.$data.tableFilters.includeTracking = false;
        } else if (this.$data.tableFilters.selectedStatus == 'SUSPENDED') {
          fullInfoPath += '&statusOnly=SUSPENDED';
          this.$data.tableFilters.includeTracking = false;
        } else if (this.$data.tableFilters.selectedStatus == 'BLOCKED') {
          fullInfoPath += '&statusOnly=BLOCKED';
          this.$data.tableFilters.includeTracking = false;
        } else if (this.$data.tableFilters.selectedStatus == 'TRACKING_ONLY') {
          fullInfoPath += '&statusOnly=TRACKING_ONLY';
        } else if (this.$data.tableFilters.includeTracking) {
          fullInfoPath += '&includeTracking=true';
        }

        fetch(fullInfoPath, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.projects = [];
                this.$data.projectsFiltered = [];
                for (const user of json.data.proxyUsers.all) {
                  const pic = this.$data.pics[Math.floor(Math.random() * this.$data.pics.length)];
                  const buf = {
                    id: user.id,
                    login: user.login,
                    imgSrc: pic,
                    date: new Date(user.created).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric' }),
                    balance: user.trafficBalanceString,
                    usedTotal: user.trafficConsumedString.replace('undefined', '0'),
                    usedToday: user.trafficConsumedTodayString.replace('undefined', '0'),
                    status: user.status,
                  }
                  this.$data.projects.push(buf);
                }
                // this.$data.projectsFiltered = this.$data.projects.filter(item => item.login.toLowerCase().includes(this.searchValue.toLowerCase()));
                this.$data.projectsFiltered = this.$data.projects;
                this.$data.projectsFilteredCurrentPage = this.$data.projectsFiltered;

                // this.resetPage(json.data.proxyUsers.count);
                this.resetPage(1); // don't use pagination when search is not empty
                this.$data.dialogLoading = false;
              } catch(e) {
                this.$data.dialogLoading = false;
                console.log('Parsing error!!!!!!!!!!!', e);
              }
            } else if (json && json.error) {
              this.$data.dialogLoading = false;
              alert(json.error);
            } else {
              this.$data.dialogLoading = false;
              throw new Error('Authentication error');
            }
          })
          .catch((e) => {
            this.$data.dialogLoading = false;
            console.log('Fetch error', e);
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },





    editPage: function(e) {
      const login = e.target.parentElement.parentElement.children[0].innerText;
      let info = null;
      const data = this.$data.projects;
      for (const user of data) {
        if (user.login == login) {
          info = user;
          break;
        }
      }
      if (info) {
        this.$router.push(this.$route.query.redirect || `/edit-user?user=${info.id}`)
      } else {
        alert('Error occured')
      }

    },
    updatePrices: function(e) {
        this.$data.dialogLoading = true;
        fetch(this.$data.host + this.paths.usersFlexPlans, {
            method: "PUT",
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${this.$data.token}`
            },
            body: JSON.stringify({
                enabled: this.enabled ? 1 : 0,
                plans: this.prices,
                userId: this.clickedUserId
            })
        })
            .then(response => {
                console.log(response);
                if (response.ok) {
                    console.log('OK');
                    return response.json()
                } else {
                    console.log('NOT OK');
                    return response.json();
                }
            })
            .then(json => {
                this.$data.priceDialog = false;
                if (json) {
                    // Header cards
                    try {
                        this.$data.dialogLoading = false;
                        this.$data.priceDialog = false;
                        alert('Updated.');
                    } catch(e) {
                        this.$data.dialogLoading = false;
                        console.log('Parsing error!!!!!!!!!!!', e);
                    }
                } else if (json && json.error) {
                    this.$data.dialogLoading = false;
                    alert(json.error);
                } else {
                    this.$data.dialogLoading = false;
                    throw new Error('Authentication error');
                }
            })
            .catch((e) => {
                this.$data.dialogLoading = false;
                console.log('Fetch error', e);
            });
    },

    editPrices: function(id) {
        fetch(this.$data.host + this.paths.usersFlexPlans + '?id=' + id, {
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Authorization': `Bearer ${this.$data.token}`
            }
        })
            .then(response => {
                console.log(response);
                if (response.ok) {
                    console.log('OK');
                    return response.json()
                } else {
                    console.log('NOT OK');
                    return response.json();
                }
            })
            .then(json => {

                if (json && json.data) {
                    // Header cards
                    try {
                        if (json.data && json.data.enabled !== undefined && json.data.plans.length > 0) {
                            this.prices = json.data.plans;
                            this.enabled = json.data.enabled == 1 ? true : false;
                        }else{
                            this.prices = [...this.defaultPrices];
                            this.enabled = false;
                        }
                        this.clickedUserId = id;
                        console.log(this.prices);
                        this.priceDialog = true;
                    } catch(e) {
                        console.log('Parsing error!!!!!!!!!!!', e);
                    }
                } else if (json && json.error) {
                    this.$data.dialogLoading = false;
                    alert(json.error);
                } else {
                    this.$data.dialogLoading = false;
                    throw new Error('Authentication error');
                }
            })
            .catch((e) => {
                this.$data.dialogLoading = false;
                console.log('Fetch error', e);
            });
    },
    suspendUser: function(e) {
      const login = e.target.parentElement.parentElement.children[0].innerText;
      let info = null;
      const data = this.$data.projects;
      for (const user of data) {
        if (user.login == login) {
          info = user;
          break;
        }
      }
      if (info) {
        let action = 'suspend';
        let path = `${this.$data.paths.users}/${info.id}/suspend`;
        if (info.status == 'SUSPENDED') {
          action = 'activate';
          path = `${this.$data.paths.users}/${info.id}/activate`;
        };

        fetch(`${this.$data.host}${path}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.status == 'SUCCESS') {
              // Header cards
              alert(action == 'suspend' ? 'The user is suspended!' : 'The user is activated!');
              info.status = action == 'suspend' ? 'SUSPENDED' : 'ACTIVE';
            } else if (json && json.error) {
              alert(json.error);
            } else {
              throw new Error('Authentication error');
            }

          })
          .catch((e) => {
            console.log('Operation failed');
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
        });
      } else {
        alert('Error occured')
      }
    },

    openMigrateConfirmDialog: function(e) {
      const login = e.target.parentElement.parentElement.children[0].innerText;
      const users = this.$data.projects;
      let target = null;
      for (const user of users) {
        if (user.login == login) {
          target = user;
          break;
        }
      }
      if (target) {
        this.$data.migrateId = target.id;
        this.$data.migrateLogin = target.login;
        this.$data.dialogConfirmMigrate = true;

      } else {
        alert('Error occured')
      }
    },

    cancelMigration: function() {
      this.$data.dialogConfirmMigrate = false;
      this.$data.migrateId = '';
      this.$data.migrateLogin = '';
      this.$data.migrateEmail = '';
      this.$data.migrateBalance = 0;
      this.$data.migrateHost = '';
      this.$data.migratePort = 0;
    },

    migrateIntoSystem: function(e) {
      if (!this.$data.migrateId || !this.$data.migrateLogin || !this.$data.migrateEmail || !this.$data.migrateBalance) {
        alert('Required fields are missing!');
        return;
      }

      if (this.$data.host != 'https://dashboard.residential.rayobyte.com') {
        this.$data.dialogConfirmMigrate = false;
        this.$data.migrateId = '';
        this.$data.migrateLogin = '';
        this.$data.migrateEmail = '';
        this.$data.migrateBalance = 0;
        this.$data.migrateHost = '';
        this.$data.migratePort = 0;
        alert('Users migration is allowed only on production environment!');
        return;
      }

      this.$data.dialogLoading = true;

      const postBody = {
        email: this.$data.migrateEmail,
        balanceMb: +this.$data.migrateBalance,
        proxyHost: this.$data.migrateHost || undefined,
        proxyPort: this.$data.migratePort ? +this.$data.migratePort : undefined,
      };

      console.log('Confirmed:', this.$data.migrateLogin, this.$data.migrateEmail, this.$data.migrateId);
      let path = `${this.$data.paths.users}/${this.$data.migrateId}/migrate`;

      fetch(`${this.$data.host}${path}`, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.$data.token}`
        },
        body: JSON.stringify(postBody) // body data type must match "Content-Type" header
      })
        .then(response => {
          console.log(response);
          if (response.ok) {
            console.log('OK');
            return response.json()
          } else {
            console.log('NOT OK');
            return response.json();
          }
        })
        .then(json => {
          console.log('DATA:', json);

          if (json && json.status == 'SUCCESS') {
            this.$data.dialogLoading = false;
            this.$data.dialogConfirmMigrate = false;
            this.$data.migrateId = '';
            this.$data.migrateLogin = '';
            alert('The user migrated successfully');
            window.location.reload();
          } else if (json && json.error) {
            this.$data.dialogLoading = false;
            this.$data.dialogConfirmMigrate = false;
            this.$data.migrateId = '';
            this.$data.migrateLogin = '';
            alert(json.error);
          } else {
            this.$data.dialogLoading = false;
            this.$data.dialogConfirmMigrate = false;
            this.$data.migrateId = '';
            this.$data.migrateLogin = '';
            throw new Error('Authentication error');
          }
        })
        .catch((e) => {
          this.$data.dialogLoading = false;
          this.$data.dialogConfirmMigrate = false;
          this.$data.migrateId = '';
          this.$data.migrateLogin = '';
          console.log('Operation failed', e);
          // localStorage.removeItem('jwt');
          // localStorage.removeItem('user'); //TODO: fix
          // alert('Authentication expired');
          // this.$router.push('/login')
      });
    },

    deleteUser: function(e) {
      const login = e.target.parentElement.parentElement.children[0].innerText;
      let info = null;
      const data = this.$data.projects;
      for (const user of data) {
        if (user.login == login) {
          info = user;
          break;
        }
      }
      if (info) {
        let path = `${this.$data.paths.users}/${info.id}`;

        fetch(`${this.$data.host}${path}`, {
          method: 'delete',
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.status == 'SUCCESS') {
              // Header cards
              alert('The user is deleted!');
              window.location.reload();
            } else if (json && json.error) {
              alert(json.error);
            } else {
              throw new Error('Authentication error');
            }

          })
          .catch((e) => {
            console.log('Operation failed');
            // localStorage.removeItem('jwt');
            // localStorage.removeItem('user'); //TODO: fix
            // alert('Authentication expired');
            // this.$router.push('/login')
        });
      } else {
        alert('Error occured')
      }
    },
    changeMain: function(e) {
      let target = e.target;
      for (let i = 0; i < 10; i++) {
        if (target.outerHTML.startsWith('<tr')) {
          break;
        } else {
          target = target.parentElement;
        }
      }

      const login = target.children[0].innerText.trim();
      let info = null;
      const data = this.$data.projects;
      for (const user of data) {
        if (user.login == login) {
          info = user;
          break;
        }
      }
      if (info) {
        this.$emit('clicked', info);
      } else {
        console.log('Error occured')
      }
    },
    resetPage(usersTotal = 1000) {
      this.$data.projectsFilteredCurrentPage = [];
      this.$data.pagination.page = 1;
      this.$data.pagination.startIndex = 0;
      for (let i = 0; i < this.$data.pagination.pageSize; i++) {
        if (i < this.$data.projectsFiltered.length) {
          this.$data.projectsFilteredCurrentPage.push(this.$data.projectsFiltered[i]); // page 1
        }
      }
      this.$emit('updateTotalPages', Math.ceil(usersTotal / this.$data.pagination.pageSize));
    }
  },
  beforeMount(){
     this.getFullInfo2()
  },
  mounted(){

  }
};
</script>
